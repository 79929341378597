@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.addContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  margin: 50px 20%;
  padding: 30px;
  text-align: center;
  background-color: $color-info-400;
  color: $color-light;
  font-size: $font-size-lg;
  border-radius: 8px;
}
