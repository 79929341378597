@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

/* Navigation arrows */
$navigation-width: auto !default;
$navigation-height: auto !default;
$navigation-border: 1px solid $carousel-navigation !default;
$navigation-color: $carousel-navigation !default;
$navigation-background: $carousel-navigation-background !default;
$navigation-transition: all 1s !default;
$navigation-hover-border: 1px solid $color-black !default;
$navigation-hover-color: $color-black !default;
$navigation-hover-background: inherit !default;

/* Pagination indicators */
$indicator-width: 8px !default;
$indicator-height: 8px !default;
$indicator-outline: 2px solid $carousel-navigation !default;
$indicator-outline-offset: 1px !default;
$indicator-background-color: $color-light !default;
$indicator-margin: $space-xxs !default;
$indicator-active-outline: 2px solid $carousel-navigation !default;
$indicator-active-background-color: $carousel-navigation !default;

.outerContainer {
  position: relative;
  overflow: hidden;
}

.contentContainer {
  width: min(100%, $wrapper-max-width);
  margin-inline: auto;
}

.title {
  font-size: $font-size-lg;
  font-weight: $font-weight-thin;
}

.slideContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

:is(body) {
  .sliderContainer {
    margin-inline: auto;
  }

  .navigation {
    @include circle;

    width: $navigation-width;
    height: $navigation-height;
    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    transition: border 1s, color 1s;
    background: $carousel-navigation-background;

    svg {
      transform: scale(0.7);
      width: fit-content;
    }

    @media (hover: hover) {
      &:hover {
        border: 1px solid $color-black;
        color: $color-black;
      }
    }
  }
}